













































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import WithHeaderLayout from "@/layouts/WithHeaderLayout.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import Dropdown, { Option } from "@/components/dropdowns/Dropdown.vue"
import User from "@/models/User"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { taskContainer } from "@/containers/TaskContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { required } from "@/utilities/Validation"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"

interface State {
  isFormValid: boolean
  isLoadingCreate: boolean
  subject: string
  planId: string
  bucketId: string
  progress: number
  priority: number
  members: Array<User>
  users: Array<User>
  plans: Array<Option> | null
  buckets: Array<Option> | null
}

export default defineComponent({
  props: {
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    subject: {
      type: String,
    },
  },
  components: {
    TextField,
    Dropdown,
    RichEditor,
    I18nFormattedMessage,
    WithHeaderLayout,
    DefaultDialog,
    UsersAutoComplete,
  },
  setup(props, { emit }) {
    const { getContext, getMembers } = teamsContextContainer.useContainer()
    const {
      getPlansAsync,
      getBucketsAsync,
      createTaskAsync,
    } = taskContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const state = reactive<State>({
      isFormValid: true,
      isLoadingCreate: false,
      subject: "",
      planId: "",
      bucketId: "",
      progress: 0,
      priority: 5,
      members: [],
      users: [],
      plans: null,
      buckets: null,
    })

    const reset = async () => {
      const context = await getContext()
      state.members = (await getMembers()).map(m => new User(m))
      state.plans = (await getPlansAsync(context.groupId)).map(
        p => ({ text: p.title, value: p.id } as Option)
      )
      state.subject = props.subject || ""
      state.progress = 0
      state.priority = 5
      state.users = state.members.filter(m => m.userId === context.userObjectId)
    }
    const requiredRule = [required]

    watch(
      () => props.isOpenDialog,
      () => {
        reset()
      }
    )

    watch(
      () => props.subject,
      newValue => {
        state.subject = newValue || ""
      }
    )

    watch(
      () => state.planId,
      async newPlanid => {
        state.buckets = null
        state.buckets = (await getBucketsAsync(newPlanid)).map(
          p => ({ text: p.name, value: p.id } as Option)
        )
      }
    )

    const onOk = async () => {
      state.isLoadingCreate = true
      await createTaskAsync({
        title: state.subject,
        planId: state.planId,
        bucketId: state.bucketId,
        percentComplete: state.progress,
        priority: state.priority,
        users: state.users.map(u => u.userId || ""),
      })
      state.isLoadingCreate = false
      showSuccessMessage("タスクを作成しました")
      emit("cancel")
    }

    const onCancel = () => {
      emit("cancel")
    }

    onMounted(async () => {
      await reset()
    })

    return {
      state,
      onOk,
      onCancel,
      requiredRule,
    }
  },
})
