











import { defineComponent } from "@vue/composition-api"
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"

export default defineComponent({
  props: {
    headerHeight: {
      type: Number,
      default: 64,
    },
  },

  setup(_props, context) {
    const { header, body } = context.slots
    if (!header) throw new RegularMeetingSolutionError({ message: "no-header" })
    if (!body) throw new RegularMeetingSolutionError({ message: "no-body" })

    return {}
  },
})
