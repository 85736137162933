

















































import {
  defineComponent,
  reactive,
  computed,
  PropType,
  watch,
} from "@vue/composition-api"
import { ruleFunction } from "@/utilities/ruleFunction"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"

export interface Option {
  text: string
  value: string | number
}

interface State {
  theValue: string | number
}

export default defineComponent({
  components: {
    I18nFormattedMessage,
  },

  props: {
    disabled: Boolean,
    error: Boolean,
    hideDetails: Boolean,
    hasBoxBorder: Boolean,
    items: {
      type: Array,
      required: true,
    },
    label: String,
    lineBreakable: Boolean,
    loading: Boolean,
    value: {
      type: [String, Number],
      required: true,
    },
    itemText: { type: String, default: "text" },
    itemValue: { type: String, default: "value" },
    noDataText: {
      type: String,
      defualt: "選択項目がありません",
    },
    rules: Array as PropType<ruleFunction[]>,
    showTimeDuration: Boolean, //開始時刻と終了時刻の長さを表示したい時に利用
    readonly: Boolean,
  },

  setup(props, { emit }) {
    const state = reactive<State>({
      theValue: props.value,
    })

    const menuProps = computed<object>(() => {
      const propsForVMenu = {
        contentClass: "",
        maxHeight: 275, //スクロール可能であることを気づかせるためDefaultの304より低く設定
      }

      if (props.hasBoxBorder) {
        propsForVMenu.contentClass = "bordered-dropdown elevation-16"
        propsForVMenu.maxHeight = 280 //枠を追加しているためこの値を設定
      }
      if (props.lineBreakable) {
        //ドロップダウンボックス内のリストアイテムを改行可能にする。
        propsForVMenu.contentClass += "line-breakable"
      }

      return propsForVMenu
    })

    const getDefaultRules = (): Array<ruleFunction> => {
      const rules: Array<ruleFunction> = []

      rules.push(value => !!value || value === 0 || "選択は必須です。")

      return rules
    }

    const combinedRules = computed<Array<ruleFunction>>(() =>
      getDefaultRules().concat(props.rules || [])
    )

    const onTheValueChanged = (value: string | number) => {
      emit("input", value)
    }

    watch(
      () => props.value,
      newValue => {
        state.theValue = newValue
      }
    )

    return {
      state,
      menuProps,
      onTheValueChanged,
      combinedRules,
    }
  },
})
